import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
import { FullViewIcon } from '../assets/icons/hiking';

export default function Sidebar() {
    const [isExpanded, setIsExpanded] = useState(true);
    const [groupedCategoriesWithTags, setGroupedCategoriesWithTags] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState({});
    const [selectedTags, setSelectedTags] = useState({});

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    const updateURLParameters = (updatedSelectedCategories, updatedSelectedTags) => {
        const selectedCategoryKeys = Object.keys(updatedSelectedCategories).filter(key => updatedSelectedCategories[key]);
        const selectedTagKeys = Object.entries(updatedSelectedTags)
            .filter(([, tags]) => tags.length > 0)
            .map(([_, tags]) => tags)
            .flat();

        const categoryParam = selectedCategoryKeys.join(',');
        const tagParam = selectedTagKeys.join(',');

        const params = new URLSearchParams();

        if (categoryParam) {
            params.append('category', categoryParam);
        }
        if (tagParam) {
            params.append('tag', tagParam);
        }

        // Update the without reloading the page
        const newUrl = `${window.location.pathname}${params.toString() ? '?' + params.toString() : ''}`;
        window.history.replaceState(null, '', newUrl);

        window.dispatchEvent(new CustomEvent('urlChange'));
    };


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const categoryParam = urlParams.get('category');
        const tagParam = urlParams.get('tag');

        const initialSelectedCategories = {};
        const initialSelectedTags = {};

        if (categoryParam) {
            categoryParam.split(',').forEach(category => {
                initialSelectedCategories[category] = true;
            });
        }

        if (tagParam) {
            tagParam.split(',').forEach(tag => {
                groupedCategoriesWithTags.forEach(category => {
                    if (category.tags.includes(tag)) {
                        if (!initialSelectedTags[category.categoryId]) {
                            initialSelectedTags[category.categoryId] = [];
                        }
                        initialSelectedTags[category.categoryId].push(tag);
                    }
                });
            });
        }

        setSelectedCategories(initialSelectedCategories);
        setSelectedTags(initialSelectedTags);
    }, [groupedCategoriesWithTags]);

    useEffect(() => {
        const eid = localStorage.getItem("default");
        const nonce = localStorage.getItem("nonce");
        const type = "product";

        function protocolpluspath() {
            return window.location.protocol + "//" + window.location.host + "/";
        }

        const url = protocolpluspath() + "admin/getData.php?eid=" + eid + "&type=" + type + "&search=" + "&nonce=" + nonce + "&filter=";

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                if (data.success && data.data && data.data.response) {
                    const categoryTagMap = {};

                    data.data.response.forEach(item => {
                        const categoryId = item.identifiers.category_id;
                        const tagsString = item.product.tags;

                        if (categoryId) {
                            const tags = tagsString ? tagsString.split(',').map(tag => tag.trim()) : [];

                            if (!categoryTagMap[categoryId]) {
                                categoryTagMap[categoryId] = new Set();
                            }

                            tags.forEach(tag => {
                                if (tag) {
                                    categoryTagMap[categoryId].add(tag);
                                }
                            });
                        }
                    });

                    const groupedCategoriesWithTags = Object.entries(categoryTagMap).map(([categoryId, tagsSet]) => ({
                        categoryId,
                        tags: Array.from(tagsSet)
                    }));

                    setGroupedCategoriesWithTags(groupedCategoriesWithTags);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const handleCategoryChange = (categoryId) => {
        const isCategorySelected = selectedCategories[categoryId];
        const updatedSelectedCategories = {
            ...selectedCategories,
            [categoryId]: !isCategorySelected,
        };

        setSelectedCategories(updatedSelectedCategories);

        const updatedTags = groupedCategoriesWithTags.find(cat => cat.categoryId === categoryId)?.tags || [];
        setSelectedTags({
            ...selectedTags,
            [categoryId]: !isCategorySelected ? updatedTags : [],
        });

        updateURLParameters(updatedSelectedCategories, {
            ...selectedTags,
            [categoryId]: !isCategorySelected ? updatedTags : [],
        });
    };

    const handleTagChange = (categoryId, tag) => {
        const selectedTagsForCategory = selectedTags[categoryId] || [];
        let updatedTags;

        if (selectedTagsForCategory.includes(tag)) {
            updatedTags = selectedTagsForCategory.filter(t => t !== tag);
        } else {
            updatedTags = [...selectedTagsForCategory, tag];
        }

        const updatedSelectedTags = {
            ...selectedTags,
            [categoryId]: updatedTags,
        };

        setSelectedTags(updatedSelectedTags);

        const updatedSelectedCategories = {
            ...selectedCategories,
            [categoryId]: updatedTags.length > 0,
        };

        setSelectedCategories(updatedSelectedCategories);
        updateURLParameters(updatedSelectedCategories, updatedSelectedTags);
    };

    return (
        <div className="flex justify-start m-1 hidden sm:inline">
            <div className="flex min-h-screen">

                <div className={` ${isExpanded ? 'w-[250px]' : 'w-[25px]'}`}>
                    <ul className="pt-5">
                        <li className="flex justify-between items-center text-sm font-semibold">
                            {isExpanded && <span className="px-2">Filter By</span>}
                            <div className="cursor-pointer m-4" onClick={toggleExpansion}>
                                <FullViewIcon />
                            </div>
                        </li>
                        {isExpanded && <hr className="my-6 border-gray-200 border-1" />}
                        {isExpanded && (
                            <div className="px-2 font-semibold">
                                {groupedCategoriesWithTags.map((category) => (
                                    <div key={category.categoryId} className="mb-4">
                                        {/* Category Level */}
                                        <div className="flex justify-between items-center mb-2">
                                            <span className="text-sm text-black">{category.categoryId}</span>
                                            <Checkbox
                                                className="custom-checkbox"
                                                checked={!!selectedCategories[category.categoryId]}
                                                onChange={() => handleCategoryChange(category.categoryId)}
                                            />
                                        </div>
                                        {/* Tags Level - Nested under Category */}
                                        {category.tags.map((tag, index) => (
                                            <div key={index} className="flex justify-between items-center mb-1 ml-4">
                                                <span className="text-sm text-gray-700">{tag}</span>
                                                <Checkbox
                                                    className="custom-checkbox"
                                                    checked={(selectedTags[category.categoryId] || []).includes(tag)}
                                                    onChange={() => handleTagChange(category.categoryId, tag)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        )}
                    </ul>
                </div>

            </div>
        </div>
    )
}
