import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

export function Redirect() {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.clear();

        document.cookie.split(';').forEach((c) => {
            document.cookie = c
                .replace(/^ +/, '')
                .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
        });

        const timer = setTimeout(() => {
            navigate("/login");
        }, 3541);

        return () => clearTimeout(timer);
    }, [navigate]);

    const handleLoginClick = () => {
        navigate("/login");
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className="flex-parent flex justify-between px-3">
                <div className="absolute top-0 left-0 m-4">
                    <p>
                        Access Denied or session expired. You are being logged out... redirecting to{' '}
                        <span
                            className="text-blue-500 cursor-pointer hover:underline"
                            onClick={handleLoginClick}
                        >
                            login page
                        </span>
                    </p>
                </div>
            </div>
        </motion.div>
    );
}
