import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import { Backdrop, CircularProgress } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import { Skeleton } from '@mui/material';

function Orders() {

    const [orders, setOrders] = useState([]);
    const [orderDetails, setOrderDetails] = useState({});
    const [priceLookUpLoading, setPriceLookUpLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const skeletonCount = 4;

    const fetchOrderItems = (order_number) => {
        const eid = localStorage.getItem('default');
        const type = "order_items";

        function protocolpluspath() {
            return window.location.protocol + '//' + window.location.host + '/';
        }
        setPriceLookUpLoading(true);

        const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type + '&nonce=' + order_number;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                setOrderDetails(data);
            })
            .catch(error => console.error('Error:', error))
            .finally(() => {
                setPriceLookUpLoading(false);
            });
    };


    useEffect(() => {
        const eid = localStorage.getItem('default');
        const type = "order";
        const defaultNonce = localStorage.getItem("nonce");

        function protocolpluspath() {
            return window.location.protocol + '//' + window.location.host + '/';
        }

        const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type + '&default=' + defaultNonce;
        setIsLoading(true);

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    const formattedOrders = data.data.response.map(order => {

                        const formattedItems = {
                            count: order.order.order_items?.count || "0",
                            product_info: (order.order.order_items?.product_info || []).map(item => ({
                                ...item,
                                images_primary: item.product_info?.images?.images_primary || "",
                            })),
                        };

                        return {
                            ...order,
                            order_items: formattedItems,
                        };
                    });

                    setOrders(formattedOrders.reverse());
                }
            })
            .catch(error => console.error('Error:', error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);




    const [selectedOrder, setSelectedOrder] = useState(null);

    const fetchOrderDetails = (nonce, defaultTab = "Details") => {
        const protocolpluspath = () => window.location.protocol + '//' + window.location.host + '/';
        const eid = localStorage.getItem('default');
        const type = "order";
        const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type + '&id=' + nonce;

        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('API Response:', data);
                if (data.success && data.data.response) {
                    setSelectedOrder(data.data.response[0]);
                    setShowPopup(true);
                    setActiveTab(defaultTab); // Set the active tab here
                }
            })
            .catch(error => {
                console.error('Error processing data:', error);
            });
    };



    const [showPopup, setShowPopup] = React.useState(false);
    const [activeTab, setActiveTab] = React.useState("Details");

    function OrderDetailsPopup({ closePopup, orderData }) {
        const order = orderData && orderData.order ? orderData.order : {};

        const { order_number = '', customer_name = '', address1 = '', city = '', state = '', zip = '', shipping_instructions = '', } = order;

        return (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-start pt-20">
                <div className="bg-white p-5 rounded-lg shadow-lg w-full max-w-2xl max-h-[calc(100%-80px)] overflow-y-auto pt-20">

                    <h2 className="text-lg font-semibold mb-4">Order Number: {order_number}</h2>

                    <div className="flex justify-between items-center mb-2">
                        <div className="flex-1 mr-2">
                            <label className="text-sm font-semibold">Sold to:</label>
                            <p> {customer_name}</p>
                            <p>{address1}</p>
                            <p>{city}</p>
                            <p>{state}</p>
                            <p>{zip}</p>
                        </div>

                        <div className="flex-1 ml-2">
                            <label className="text-sm font-semibold">
                                Shipped to:
                            </label>
                            <p> {customer_name}</p>
                            <p>{address1}</p>
                            <p>{city}</p>
                            <p>{state}</p>
                            <p>{zip}</p>
                        </div>
                    </div>

                    <div className="flex justify-between items-center mb-2">
                        <div className="flex-1 mr-2">
                            <label className="text-sm font-semibold">Shipping Comments:</label>
                            <p>{shipping_instructions}</p>
                        </div>

                        <div className="flex-1 ml-2">
                            <label className="text-sm font-semibold">Contact Name:</label>
                        </div>
                    </div>

                    <div className="flex  mt-4 border-b border-gray-300">
                        <button
                            className={`py-2 px-4 ${activeTab === "Details" ? "text-red-600 border-b-2 border-red-600" : "text-gray-500"} rounded-t`}
                            onClick={() => setActiveTab("Details")}
                        >
                            Details
                        </button>
                        <button
                            className={`py-2 px-4 ml-2 ${activeTab === "Shipments" ? "text-red-600 border-b-2 border-red-600" : "text-gray-500"} rounded-t`}
                            onClick={() => setActiveTab("Shipments")}
                        >
                            Shipments
                        </button>
                    </div>


                    <div className="mt-4">
                        {activeTab === "Details" && (
                            <div>


                                <div className="flex justify-between items-center mb-2">
                                    <div className="flex-1 mr-2">
                                        <label className="text-sm font-semibold">
                                            Shipped Complete =
                                            <span className="inline-block w-3 h-3 bg-customGreen ml-1 mr-4"></span>
                                            Shipped Partial =
                                            <span className="inline-block w-3 h-3 bg-yellow-200 ml-1 mr-4"></span>
                                            Not Shipped =
                                            <span className="inline-block w-3 h-3 bg-red-200 ml-1 mr-4"></span>
                                        </label>
                                    </div>
                                </div>


                                <div className="orders-list mt-4">
                                    {orderDetails && orderDetails.data && orderDetails.data.response.map((item, index) => (
                                        <div key={index} className="bg-white mb-4 divide-y divide-gray-200 border border-gray-400">
                                            <div className="bg-red-200 px-4 py-2 flex justify-between items-center">
                                                <div className="flex-1 flex flex-col items-center">
                                                    <div className="font-semibold text-sm">ITEM NO.</div>
                                                    <div className="font-semibold text-sm  text-gray-600">{item.item_number}</div>
                                                </div>
                                                <div className="flex-1 flex flex-col items-center">
                                                    <div className="font-semibold text-sm">DESCRIPTION</div>
                                                    <div className="font-semibold text-sm  text-gray-600"> {item.product_info.short_description}</div>
                                                </div>
                                                <div className="flex-1 flex flex-col items-center">
                                                    <div className="font-semibold text-sm ">QTY</div>
                                                    <div className="font-semibold text-sm  text-gray-600"> <p>{item.quantity}</p> </div>
                                                </div>
                                                <div className="flex-1 flex flex-col items-center">
                                                    <div className="font-semibold text-sm ">U/M</div>
                                                    <div className="font-semibold text-sm  text-gray-600"><p>Box</p></div>
                                                </div>
                                            </div>

                                            <div className="px-3 py-1 flex justify-between items-center">
                                                <div className="flex items-center">
                                                    <div className="font-semibold text-sm mr-2">Date Shipped:</div>
                                                    <div className="font-semibold text-sm mr-2"></div>
                                                </div>
                                                <div className="flex items-center">
                                                    <div className="font-semibold text-sm mr-2">QTY:</div>
                                                    <div className="font-semibold text-sm mr-2">
                                                        <p className="font-semibold text-sm mr-2"></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>

                        )}
                        {activeTab === "Shipments" && (
                            <div>
                                <div className="flex justify-between items-center mb-2">
                                    <div className="flex-1 mr-2">
                                        <label className="text-sm font-semibold">
                                            Shipped Complete =
                                            <span className="inline-block w-3 h-3 bg-green-200 ml-1 mr-4"></span>
                                            Shipped Partial =
                                            <span className="inline-block w-3 h-3 bg-yellow-200 ml-1 mr-4"></span>
                                            Not Shipped =
                                            <span className="inline-block w-3 h-3 bg-red-200 ml-1 mr-4"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="orders-list mt-4">
                                    <div className="bg-white mb-4 divide-y divide-gray-200 border border-gray-400">
                                        <div className="bg-gray-300 px-3 py-1 flex justify-between items-center">
                                            <div className="flex items-center">
                                                <div className="font-semibold text-sm mr-2">Shipment Number:</div>
                                                <div className="font-semibold text-sm mr-2">100112370</div>
                                            </div>
                                            <div className="flex items-center">
                                                <div className="font-semibold text-sm mr-2">Invoice Number:</div>
                                                <div className="font-semibold text-sm mr-2">
                                                    <p className="text-blue-500 cursor-pointer underline">20310511</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-4 py-2 flex justify-between items-center">
                                            <div className="flex-1 flex justify-center items-center">
                                                <button
                                                    className="bg-red-600 text-white text-sm font-bold py-1 px-2 rounded"
                                                >
                                                    More
                                                </button>
                                            </div>
                                            <div className="flex-1 flex flex-col items-center">
                                                <div className="font-semibold text-sm">Ship Date</div>
                                                <div className="font-semibold text-sm  text-gray-600">2023-04-28</div>
                                            </div>
                                            <div className="flex-1 flex flex-col items-center">
                                                <div className="font-semibold text-sm ">Carrier:</div>
                                                <div className="font-semibold text-sm  text-gray-600"> <p>UPS</p> </div>
                                            </div>
                                            <div className="flex-1 flex flex-col items-center">
                                                <div className="font-semibold text-sm ">Tracking:</div>
                                                <div className="font-semibold text-sm  text-gray-600"><p>status</p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>


                    <div className="flex justify-end mt-6">
                        <button onClick={closePopup} className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2">Close</button>
                    </div>
                </div>
            </div>
        );
    }


    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={priceLookUpLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <h1 className="text-2xl font-bold mb-6">Recent Orders

            </h1>

            {isLoading ? (
                Array.from({ length: skeletonCount }).map((_, index) => (
                    <div className="border border-gray-200 rounded-lg p-4 bg-gray-100 mb-4">
                        <div className="flex justify-between items-center mb-4">
                            <div className="flex flex-col space-y-2">
                                <div className="flex items-center justify-between gap-x-4">
                                    <Skeleton width={120} height={24} />
                                    <Skeleton width={80} height={24} />
                                </div>
                                <Skeleton width={150} height={16} />
                                <Skeleton width={100} height={16} />
                            </div>
                            <div className="space-x-2">
                                <Skeleton width={120} height={32} />
                                <Skeleton width={120} height={32} />
                            </div>
                        </div>

                        <Skeleton width="100%" height={2} />

                        <div className="flex justify-between items-start mt-4">
                            <div className="flex space-x-4">
                                <Skeleton circle={true} width={50} height={50} />
                                <Skeleton circle={true} width={50} height={50} />
                                <Skeleton circle={true} width={50} height={50} />
                                <Skeleton width={80} height={16} />
                            </div>
                            <div className="text-right">
                                <Skeleton width={150} height={16} />
                                <Skeleton width={100} height={16} />
                                <Skeleton width={100} height={16} />
                                <Skeleton width={80} height={16} />
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                orders.map(order => (
                    <div className="border border-gray-200 rounded-lg p-4 bg-gray-100 mb-4">
                        {/* Top Section */}
                        <div className="flex justify-between items-center mb-4">
                            <div className="flex flex-col space-y-2">
                                <div className="flex items-center justify-between gap-x-4">
                                    <p className="text-lg font-bold text-black">
                                        Order No: {order.order.order_number}
                                    </p>
                                    <span className="px-3 py-1 bg-yellow-400 text-white rounded-full text-xs">
                                        {order.order.status}
                                    </span>
                                </div>
                                <p className="text-sm text-black">
                                    P.O. Number: {order.order.po_number}
                                </p>
                                <p className="text-sm text-gray-500">
                                    Date: {order.order.created_date}
                                </p>
                            </div>


                            <div className="space-x-2">
                                <button
                                    onClick={() => {
                                        fetchOrderDetails(order.order.nonce, "Shipments");
                                        fetchOrderItems(order.order.order_number);
                                    }}
                                    className="px-4 py-2 text-sm bg-gray-300 rounded"
                                >
                                    Shipment Data
                                </button>
                                <button
                                    onClick={() => {
                                        fetchOrderDetails(order.order.nonce, "Details");
                                        fetchOrderItems(order.order.order_number);
                                    }}
                                    className="px-4 py-2 text-sm bg-black text-white rounded"
                                >
                                    View Details
                                </button>
                            </div>
                        </div>

                        {/* Divider */}
                        < hr className="border-gray-200 my-4" />

                        {/* Bottom Section */}
                        <div className="flex justify-between items-start">
                            <div className="flex space-x-4">
                                {(order.order_items?.product_info || []).slice(0, 3).map((item, index) => (
                                    <img
                                        key={index}
                                        src={item.images_primary}
                                        alt={`Product ${index + 1}`}
                                        className="w-24 h-24 object-cover rounded"
                                    />
                                ))}

                                {parseInt(order.order_items?.count || '0', 10) > 3 && (
                                    <p className="text-sm text-gray-500">
                                        + {parseInt(order.order_items.count || '0', 10) - 3} More Item
                                        {parseInt(order.order_items.count || '0', 10) - 3 > 1 ? 's' : ''}
                                    </p>
                                )}
                            </div>

                            <div className="text-right">
                                <p className="text-sm text-gray-500">Shipping Address:</p>
                                <p className="text-sm">
                                    {order.order.address1} <br />
                                    {order.order.city} <br />
                                    {order.order.state} <br />
                                    {order.order.zip}
                                </p>
                            </div>
                        </div>




                    </div >
                ))
            )}

            {showPopup && <OrderDetailsPopup closePopup={() => setShowPopup(false)} orderData={selectedOrder} />}

        </div >

    );
}


export default Orders;