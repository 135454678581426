import React, { createContext, useState, useContext } from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cartUpdateSignal, setCartUpdateSignal] = useState(0);

    const triggerCartUpdate = () => {
        setCartUpdateSignal((prev) => prev + 1); // Increment signal to trigger updates
    };

    return (
        <CartContext.Provider value={{ cartUpdateSignal, triggerCartUpdate }}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => useContext(CartContext);
