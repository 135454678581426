import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import { Backdrop, CircularProgress } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import { Skeleton } from '@mui/material';

function Invoice() {


    return (
        <div>
            <h1 className="text-2xl font-bold mb-6">Invoice History</h1>

            {/* Container for Each Invoice */}
            <div className="border border-gray-200 rounded-lg p-6 bg-gray-100 mb-6">
                {/* Header Section */}
                <div className="flex justify-between items-center mb-4">
                    <div>
                        <p className="text-lg font-bold text-black">Invoice No: 1732546439295</p>
                        <p className="text-sm text-gray-500">Order Number: 12345667</p>
                    </div>
                    <span className="px-3 py-1 bg-yellow-400 text-white rounded-full text-xs">
                        Pending
                    </span>
                </div>

                {/* Invoice Date and Due Date */}
                <div className="flex justify-between mb-4">
                    <div>
                        <p className="text-sm text-gray-500">Invoice Date:</p>
                        <p className="text-sm text-black">2024-11-26</p>
                    </div>
                    <div>
                        <p className="text-sm text-gray-500">Due Date:</p>
                        <p className="text-sm text-black">2021-07-12</p>
                    </div>
                </div>

                <hr className="border-gray-200 my-4" />

                {/* Invoice Details */}
                <div className="grid grid-cols-3 gap-4">
                    <div className="text-center">
                        <p className="text-sm text-gray-500">Invoice Amount</p>
                        <p className="text-black font-medium">1,372.72</p>
                    </div>
                    <div className="text-center">
                        <p className="text-sm text-gray-500">Invoice Terms</p>
                        <p className="text-black font-medium">3% Disc 20 Days net 45 Days</p>
                    </div>
                    <div className="text-center">
                        <p className="text-sm text-gray-500">Invoice Balance</p>
                        <p className="text-black font-medium">1,372.72 Unpaid</p>
                    </div>
                </div>
            </div>
        </div>


    );
}


export default Invoice;