import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import { motion } from 'framer-motion';
import { OrdersIcon, CustomerIcon, AddressIcon, PaymentIcon, OrderHistoryIcon, SettingsIcon } from "../assets/icons/hiking";
import Orders from "./orders"
import { Wysiwyg } from './wysiwyg';
import InvoiceHistory from './invoice';

export function Payers() {

    const [selectedOption, setSelectedOption] = useState('recentOrders');

    const renderContent = () => {
        switch (selectedOption) {
            case 'recentOrders':
                return <Orders />;
            case 'Wysiwyg Editor':
                return <Wysiwyg />;
            case 'invoiceHistory':
                return <InvoiceHistory />;
            default:
                return <div>Select an option</div>;
        }
    };

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} style={{ paddingTop: '150px' }}>

            <div className="flex h-screen">
                <div className="bg-gray-100 p-5" style={{ paddingRight: '100px' }}>
                    <ul className="space-y-4">

                        <li
                            className={`flex items-center gap-x-3 cursor-pointer ${selectedOption === 'recentOrders' ? 'text-blue-600' : ''}`}
                            onClick={() => setSelectedOption('recentOrders')}
                        >
                            <OrdersIcon className="text-xl" />
                            <span className="font-medium">Recent Orders</span>
                        </li>

                        <li
                            className={`flex items-center gap-x-3 cursor-pointer ${selectedOption === 'invoiceHistory' ? 'text-blue-600' : ''}`}
                            onClick={() => setSelectedOption('invoiceHistory')}
                        >
                            <OrderHistoryIcon className="text-xl" />
                            <span className="font-medium">Invoice History</span>
                        </li>

                        <li
                            className={`flex items-center gap-x-3 cursor-pointer ${selectedOption === 'Wysiwyg Editor' ? 'text-blue-600' : ''}`}
                            onClick={() => setSelectedOption('Wysiwyg Editor')}
                        >
                            <SettingsIcon className="text-xl" />
                            <span className="font-medium">Wysiwyg Editor</span>
                        </li>


                        <li className="flex items-center gap-x-3 hover:text-red-600 cursor-pointer">
                            <CustomerIcon className="text-xl" />
                            <span className="font-medium">My Account</span>
                        </li>


                        <li className="flex items-center gap-x-3 hover:text-red-600 cursor-pointer">
                            <PaymentIcon className="text-xl" />
                            <span className="font-medium">My Address Info</span>
                        </li>


                        {/* <li className="flex items-center gap-x-3 hover:text-blue-600 cursor-pointer">
                            <SettingsIcon className="text-xl" />
                            <span className="font-medium">Account Settings</span>
                        </li> */}

                    </ul>
                </div>

                {/* left pannel content */}
                <div className="flex-1 bg-white p-6 rounded-lg border border-gray-300 overflow-y-auto">
                    {renderContent()}
                </div>
            </div>

        </motion.div>
    );




}
