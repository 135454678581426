import React from 'react';
import { motion } from "framer-motion";


// images
import trcOne from '../assets/images/trc-one.png';
import tech from '../assets/images/tech.png';
import pang from '../assets/images/pang.png';
import salvadori from '../assets/images/salvadori.png';
import ams from '../assets/images/ams.png';



export default function Terms() {

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex flex-col justify-center items-center pt-4"
            style={{ paddingTop: '150px' }}
        >

            <div className="flex flex-col w-full mt-8 items-start px-8">

                <div className="w-full max-w-6xl mx-auto">
                    <img src={trcOne} className="mb-4 w-40" />
                    <p className="text-left">
                        TRC: TRC is a multigenerational business that continually reimagines ways to enable the circular economy for
                        organizations around the world that seek to generate new value from products that were formerly thought to be at the
                        end of their useful life in an era when sustainability is critical for financial and ecological success.
                    </p>
                </div>

                <div className="w-full max-w-6xl mx-auto mt-8">
                    <img src={tech} className="mb-4 w-40" />

                    <p className="text-left">
                        TECH: TECH is the global tire & wheel service authority that personally delivers a complete range of proven solutions
                        through empowering partnerships to service professionals all over the world who need to keep their customers rolling in
                        an era when everything rides on every repair.
                    </p>
                </div>


                <div className="w-full max-w-6xl mx-auto mt-8">
                    <img src={pang} className="mb-4 w-40" />

                    <p className="text-left">
                        Pang Industrial: Pang Industrial is an independent industrial rubber repair solutions provider that delivers maximum uptime
                        to mining, shipping, and other industries all over the world who recognize that unplanned downtime is catastrophic.
                    </p>
                </div>


                <div className="w-full max-w-6xl mx-auto mt-8">
                    <img src={salvadori} className="mb-4 w-40" />

                    <p className="text-left">
                        Salvadori: Salvadori is a tire & rubber recycling business that transforms waste into new products for progressive organizations
                        seeking new revenue opportunities in an era of increased regulations, enhanced environmental consciousness, social responsibility
                        and rapid transition to the new circular economy.
                    </p>
                </div>

                <div className="w-full max-w-6xl mx-auto mt-8">
                    <img src={ams} className="mb-4 w-40" />

                    <p className="text-left">
                        AMS: Advanced Mobility Solutions (AMS) offers a complete suite of leading-edge mobility solutions to support fleets of all sizes.
                        The AMS Smart-Tread solution is a tire monitoring and management service utilizing various sensor technologies to capture a
                        multitude of tire health data which is analyzed and provides extensive data analytics and reporting.
                    </p>
                </div>

            </div>
        </motion.div>
    );
}
