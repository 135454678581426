import React from 'react';
import Plans from "./pages/home";
import Products from "./pages/products";
import Cart from "./pages/cart";
import Saved from "./pages/saved";
import Testing from "./pages/dev/testing";
import Terms from "./pages/tos";
import Welcome from './pages/welcome';
import ProductDetails from './pages/productDetails';
import { Logout } from "heroicons-react";
import { Payers } from "./pages/settings";


// app* routes
export const routes = {
    "/home": () => <Plans />,
    "/products": () => <Products />,
    "/cart": () => <Cart />,
    "/saved": () => <Saved />,
    "/settings": () => <Payers />,
    "/tos": () => <Terms />,
    "/welcome": () => <Welcome />,
    "/product": () => <ProductDetails />,
    "/testing": () => <Testing />,
    "/": () => <Logout />
};
