import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import { motion } from "framer-motion";
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNotifications } from '../context/NotificationContext';
import { Accordion, AccordionSummary, AccordionDetails, MenuItem, Select, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function CreateProduct() {

    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [description, setDescription] = useState('');
    const [categories, setCategories] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);
    const { showNotification } = useNotifications();

    useEffect(() => {
        const eid = localStorage.getItem('default');
        const type = 'categories';

        function protocolpluspath() {
            return window.location.protocol + '//' + window.location.host + '/';
        }

        const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data.success && data.data && data.data.response) {
                    setCategories(data.data.response);
                }
            })
            .catch(error => console.error('Error:', error));
    }, []);



    // Handler to add a new category block
    const addCategoryBlock = () => {
        setCategoriesData(prev => [
            ...prev,
            { categoryNonce: '', tags: [''], availableTags: [] }
        ]);
    };

    // Handler for category selection change
    const handleCategoryChange = (index, newNonce) => {
        // Update the categoryNonce for the given block
        const updated = [...categoriesData];
        updated[index].categoryNonce = newNonce;
        updated[index].tags = ['']; // reset tags since category changed
        updated[index].availableTags = [];
        setCategoriesData(updated);

        // Fetch tags for that category
        if (newNonce) {
            const eid = localStorage.getItem('default');
            const type = "tags";

            function protocolpluspath() {
                return window.location.protocol + '//' + window.location.host + '/';
            }

            const url = protocolpluspath() + 'admin/getData.php?eid=' + eid + '&type=' + type + '&a=' + encodeURIComponent(newNonce);

            fetch(url)
                .then(response => response.json())
                .then(data => {
                    if (data.success && data.data && data.data.response) {
                        const updated2 = [...categoriesData];
                        updated2[index].availableTags = data.data.response; // store the fetched tags
                        setCategoriesData(updated2);
                    } else {
                        // no tags or error, handle gracefully
                        const updated2 = [...categoriesData];
                        updated2[index].availableTags = [];
                        setCategoriesData(updated2);
                    }
                })
                .catch(error => console.error('Error:', error));
        }
    };


    const addTagField = (catIndex) => {
        const updated = [...categoriesData];
        updated[catIndex].tags.push('');
        setCategoriesData(updated);
    };


    const handleTagChange = (catIndex, tagIndex, newValue) => {
        const updated = [...categoriesData];
        updated[catIndex].tags[tagIndex] = newValue;
        setCategoriesData(updated);
    };

    useEffect(() => {
        if (categoriesData.length === 0) {
            addCategoryBlock();
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const eid = localStorage.getItem('default');

        const payload = {
            eid: eid,
            endpoint: "v1/product/add",

            title,
            slug,
            shortDescription,
            description,
            catalog_1: "Root",
            taxonomy: categoriesData.map(cat => ({
                category_nonce: cat.categoryNonce,
                tags: cat.tags.map(tag => ({
                    nonce: tag
                }))
            }))

        };

        console.log(payload);

        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success && data.response.nonce) {
                    const Notificationpayload = {
                        response: true,
                        subject: "Product",
                        operation: "Created",
                        context: "Success!!",
                    };
                    showNotification(Notificationpayload);

                } else {
                    const Notificationpayload = {
                        response: false,
                        subject: "Product",
                        operation: "Creating",
                        context: "Error!",
                    };
                    showNotification(Notificationpayload);
                }
            });
    };



    return (
        <div className="px-10">

            <div className="flex justify-between items-center py-6 pt-10">

                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="flex justify-center w-full min-h-screen pt-5"
                >

                    <div className="container bg-white shadow-2xl mx-auto rounded-lg p-6 custom-width">

                        <form onSubmit={handleSubmit} className="space-y-6 p-6 bg-gray-100 rounded-lg shadow-md">
                            <div>
                                <TextField
                                    fullWidth
                                    label="Title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>

                            <div>
                                <TextField
                                    fullWidth
                                    label="Slug"
                                    value={slug}
                                    onChange={(e) => setSlug(e.target.value)}
                                />
                            </div>

                            <div>
                                <TextField
                                    fullWidth
                                    label="Short Description"
                                    value={shortDescription}
                                    multiline
                                    rows={2}
                                    onChange={(e) => setShortDescription(e.target.value)}
                                />
                            </div>

                            <div>
                                <TextField
                                    fullWidth
                                    label="Description"
                                    value={description}
                                    multiline
                                    rows={4}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>

                            <hr className="border-gray-300" />

                            <Typography variant="h5" className="font-bold">
                                Taxonomy
                            </Typography>

                            {categoriesData.map((catBlock, catIndex) => (
                                <Accordion key={catIndex} className="bg-white">
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className="font-semibold">
                                            {catBlock.categoryNonce
                                                ? categories.find((cat) => cat.nonce === catBlock.categoryNonce)?.title || "Select Category"
                                                : "Select Category"}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="subtitle1" className="font-bold mb-2 space-y-4">
                                            Select Category
                                        </Typography>
                                        <div className="space-y-2">
                                            <Select
                                                fullWidth
                                                value={catBlock.categoryNonce}
                                                onChange={(e) => handleCategoryChange(catIndex, e.target.value)}
                                                displayEmpty
                                            >
                                                <MenuItem value="">
                                                    <em>--Select Category--</em>
                                                </MenuItem>
                                                {categories.map((cat) => (
                                                    <MenuItem key={cat.nonce} value={cat.nonce}>
                                                        {cat.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>

                                            {catBlock.categoryNonce && (
                                                <div>
                                                    <Typography variant="subtitle1" className="font-bold mb-2">
                                                        Tags for this Category
                                                    </Typography>
                                                    <div className="space-y-2">
                                                        {catBlock.tags.map((tagValue, tagIndex) => (
                                                            <div key={tagIndex} className="flex gap-2">
                                                                <Select
                                                                    value={tagValue}
                                                                    onChange={(e) =>
                                                                        handleTagChange(catIndex, tagIndex, e.target.value)
                                                                    }
                                                                    displayEmpty
                                                                    style={{ flex: 1 }}
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>--Select Tag--</em>
                                                                    </MenuItem>
                                                                    {catBlock.availableTags.map((tag) => (
                                                                        <MenuItem key={tag.nonce} value={tag.nonce}>
                                                                            {tag.title}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </div>
                                                        ))}
                                                        <Button
                                                            variant="outlined"
                                                            onClick={() => addTagField(catIndex)}
                                                            className="mt-2"
                                                        >
                                                            Add Tag
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            ))}

                            <Button variant="contained" color="primary" onClick={addCategoryBlock}>
                                Add Another Category
                            </Button>

                            <hr className="border-gray-300" />

                            <Button type="submit" variant="contained" color="success" fullWidth>
                                Save Product
                            </Button>
                        </form>



                    </div>
                </motion.div>


            </div>
        </div>
    );

}


export default CreateProduct;


