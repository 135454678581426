import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import { motion } from "framer-motion";
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { Accordion, AccordionSummary, AccordionDetails, MenuItem, Select, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Taxonomy() {


    const eid = localStorage.getItem('default');
    const [categoryTitle, setCategoryTitle] = useState('');
    const [categoryShortDescription, setCategoryShortDescription] = useState('');
    const [categoryLongDescription, setCategoryLongDescription] = useState('');
    const [tags, setTags] = useState([]);

    const handleTagChange = (index, field, value) => {
        const updatedTags = [...tags];
        updatedTags[index][field] = value;
        setTags(updatedTags);
    };

    const addTag = () => {
        setTags([
            ...tags,
            { title: '', long_description: '', short_description: '' },
        ]);
    };

    const removeTag = (index) => {
        const updatedTags = [...tags];
        updatedTags.splice(index, 1);
        setTags(updatedTags);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const payload = {
            eid,
            endpoint: "v1/taxonomy/add",
            category_title: categoryTitle,
            category_long_description: categoryLongDescription,
            category_short_description: categoryShortDescription,
            tags,
        };


        fetch('/admin/postData.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success && data.response === "OK") {
                    alert("text updated successfully!")
                } else {
                    alert("something went wrong");
                }
            })
            .catch(() => {
                alert("something went wrong");
            });

    };




    return (
        <div className="px-10">

            <div className="flex justify-between items-center py-6 pt-10">

                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="flex justify-center w-full min-h-screen pt-5"
                >

                    <div className="container bg-white shadow-2xl mx-auto rounded-lg p-6 custom-width">

                        <Typography variant="h4" className="font-bold">
                            Create Taxonomy
                        </Typography>

                        <form onSubmit={handleSubmit} className="space-y-6 p-6 bg-gray-100 rounded-lg shadow-md">

                            <Typography variant="h5" className="font-semibold">
                                Category Information
                            </Typography>

                            <div>
                                <TextField
                                    fullWidth
                                    label="Category Title"
                                    value={categoryTitle}
                                    onChange={(e) => setCategoryTitle(e.target.value)}
                                />
                            </div>

                            <div>
                                <TextField
                                    fullWidth
                                    label="Category Short Description"
                                    value={categoryShortDescription}
                                    onChange={(e) => setCategoryShortDescription(e.target.value)}
                                    multiline
                                    rows={2}
                                />
                            </div>

                            <div>
                                <TextField
                                    fullWidth
                                    label="Category Long Description"
                                    value={categoryLongDescription}
                                    onChange={(e) => setCategoryLongDescription(e.target.value)}
                                    multiline
                                    rows={4}
                                />
                            </div>

                            <hr className="border-gray-300" />

                            <Typography variant="h5" className="font-semibold">
                                Tags Information
                            </Typography>

                            {tags.map((tag, index) => (
                                <Accordion key={index} className="bg-white">
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Tag {index + 1}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="space-y-4">
                                            <TextField
                                                fullWidth
                                                label="Tag Title"
                                                value={tag.title}
                                                onChange={(e) =>
                                                    handleTagChange(index, 'title', e.target.value)
                                                }
                                            />
                                            <TextField
                                                fullWidth
                                                label="Tag Short Description"
                                                value={tag.short_description}
                                                onChange={(e) =>
                                                    handleTagChange(index, 'short_description', e.target.value)
                                                }
                                                multiline
                                                rows={2}
                                            />
                                            <TextField
                                                fullWidth
                                                label="Tag Long Description"
                                                value={tag.long_description}
                                                onChange={(e) =>
                                                    handleTagChange(index, 'long_description', e.target.value)
                                                }
                                                multiline
                                                rows={4}
                                            />
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                onClick={() => removeTag(index)}
                                            >
                                                Remove Tag
                                            </Button>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            ))}

                            <Button variant="contained" color="primary" onClick={addTag}>
                                Add Tag
                            </Button>

                            <hr className="border-gray-300" />

                            <Button type="submit" variant="contained" color="success" fullWidth>
                                Submit
                            </Button>
                        </form>

                    </div>
                </motion.div>


            </div>
        </div>
    );

}


export default Taxonomy;


