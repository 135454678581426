import { Navigate } from "react-router-dom";

// this is protecting only the /app* routes
const ProtectedRoute = ({ children }) => {
    const isAuthenticated = () => {
        const nonce = localStorage.getItem('nonce');
        const eid = localStorage.getItem('default');
        return nonce && eid;
    };

    return isAuthenticated() ? children : <Navigate to="/logout" replace />;
};

export default ProtectedRoute;
